<template>
  <span
    class="spl-nuxt-link"
    :class="{ 'full-width': fullWidth }"
  >
    <span v-if="isDisabled">
      <slot />
    </span>

    <nuxt-link
      v-else
      class="spl-nuxt-link__internal"
      :class="{ 'full-width': fullWidth }"
      :to="href"
      :hreflang="hreflang"
      :rel="rel"
      :target="target"
      :event="isDisabled ? '' : 'click'"
      :replace="replace"
      exact
      @click="onClick"
    >
      <slot />
    </nuxt-link>
  </span>
</template>

<script lang="ts">
import { addDomainIfNeeded, addFirstSlashToURL, joinPaths, slashUri } from '~/core/ts/util/string'

import { useGA4UTMParams } from '~/core/composable/shared/useGA4UTMParams'
import { useLangSelector } from '~/core/composable/shared/useLangSelector'

export default defineNuxtComponent({
  props: {
    isExternal: {
      type: Boolean,
      default: false,
    },
    // Solo para externos
    isTargetBlank: {
      type: Boolean,
      default: false,
    },
    reloadOnClick: {
      type: Boolean,
      default: false,
    },
    rel: {
      type: String,
      default: () => '',
    },
    noLang: {
      type: Boolean,
      default: false,
    },
    overrideLang: {
      type: String,
      default: () => '',
    },
    // Solo para internos
    replace: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    to: {
      type: [Object, String],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const runtimeConfig = useRuntimeConfig()
    const domain = runtimeConfig.public.domain

    const { currentLang, } = useLangSelector()

    const {
      mergeCurrentParamsWithUTM,
    } = useGA4UTMParams()

    const ObjectToQueryParams = (params: object, url = ''): string =>
      Object.entries(params)
        .reduce((prev, actual) => {
          const [param, value] = actual
          return prev.concat(`&${param}=${value}`)
        }, `${url}?`)
        .replace('?&', '?')
        .replace('??', '?')
        .replace(/\?$/, '')

    const language = props.overrideLang ? props.overrideLang : currentLang

    const shouldAddLanguaje =(() => {
      if (props.noLang) {
        return false
      }

      if (language === 'es') {
        return false
      }

      return true
    })()

    const hreflangDict: Record<string, string> = {
      es: 'es-es',
      pt: 'pt-pt',
    }

    const hreflang = (() => {
      const hl = hreflangDict[language]
      if (hl) { return hl }
      return language
    })()

    const href = computed (() => {
      const notExternalHref = (() => {
        if (props.isDisabled || !props.to) {
          return ''
        }

        if (typeof props.to === 'string') {
          if (shouldAddLanguaje) {
            return addFirstSlashToURL(joinPaths(language, slashUri(props.to)))
          }
          return slashUri(props.to)
        }

        const { path, query } = props.to

        let slashedPath = slashUri(path)
        if (shouldAddLanguaje) {
          slashedPath = addFirstSlashToURL(joinPaths(language, slashedPath))
        }

        const queryObject = new URLSearchParams(query)
        const queryStr = queryObject.toString()

        return queryStr ? `${slashedPath}?${queryStr}` : slashedPath
      })()

      const query = mergeCurrentParamsWithUTM(props.query)
      const notExternalHrefWithQuery = ObjectToQueryParams(query, notExternalHref)

      if (props.isExternal) {
        return addDomainIfNeeded(notExternalHrefWithQuery, domain)
      }

      return notExternalHrefWithQuery
    })

    const target = computed(() => (props.isTargetBlank ? '_blank' : ''))

    function onClick () {
      if (props.reloadOnClick) {
        location.reload()
      }

      emit('click')
    }

    return {
      href,
      target,

      language,
      hreflang,

      onClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.spl-nuxt-link {
  &__internal {
    cursor: pointer;
    @apply w-full;
  }
  &__external {
    @apply w-full;
  }
}
.spl-nuxt-link.full-width {
  @apply w-full;
}
.full-width {
  @apply inline-block;
  @apply w-full;
}
</style>
