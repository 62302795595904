type QueryParam = string | null
type Query = Record<string, QueryParam | QueryParam[]>

export function useGA4UTMParams() {
  const route = useRoute()

  const utmParams = computed(() => {
    const params: Query = {}
    Object.entries(route.query).forEach(([key, value]) => {
      if (key.startsWith('utm_')) {
        params[key] = value
      }
    })

    return params
  })

  function mergeCurrentParamsWithUTM(query: Query): Query {
    Object.entries(route.query).forEach(([key, value]) => {
      if (key.startsWith('utm_')) {
        query[key] = value
      }
    })

    return query
  }

  return {
    utmParams,
    mergeCurrentParamsWithUTM,
  }
}
